import React from "react"
import PropTypes from "prop-types"

const Svg = ({ svgClass, id }) => (
  <svg className={svgClass}><use xlink="https://www.w3.org/1999/xlink" href={'#'+id}></use></svg>
)

Svg.propTypes = {
  svgClass: PropTypes.string,
  id: PropTypes.string,
}

Svg.defaultProps = {
  svgClass: ``,
  id: ``,
}

export default Svg;