// import { Link } from "gatsby"
import React from "react"
// import PropTypes from "prop-types"
import classNames from 'classnames'

import roleFeatures from "../json/role-features.json"
import Svg from "./svg"
import { CDNBASE } from "../utils/maps"
// import PosShower from "./posShower"

import st from './css/roleFeature.module.scss'

class RoleFeature extends React.Component {
  state = {
    curRole: '',
    curFea: '',
    curSub: {},
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      document.onscroll = () => {
        // const scrollTop = document.documentElement.scrollTop;
        // console.log(scrollTop)
        const { isMobile } = this.props;
        const top = isMobile ? 55 : 55;
        let flag = false;

        roleFeatures.forEach(({ icon, items }) => {
          const secRect = this.refs[icon].getClientRects()[0];

          const active = secRect.top < top && (secRect.top + secRect.height) > top;
          let curFea = this.state.curFea;
          if (active) {
            const roleHeadRect = this.refs[icon + '_head'].getClientRects()[0];
            flag = true;
            items.forEach(v => {
              const ele = this.refs['stage_' + v.id];
              if (!ele) return;
              const rect = ele.getBoundingClientRect();
              // console.log(rect.height);
              // console.log(roleHeadRect.height);
              if ((rect.top - roleHeadRect.height) > -(rect.height / 2) && (rect.top - roleHeadRect.height) < (rect.height / 2)) {
                curFea = v.id;
              }
            });
            this.setState({
              // curSub: 0,
              curFea,
              curRole: icon,
            });
          }
        })
        if (!flag) {
          this.setState({
            // curSub: -1,
            // curFea: '',
            curRole: '',
          });
        }
      }
    }
  }
  componentWillUnmount() {
    document.onscroll = null;
  }

  setCurSub = (id, si, time) => {
    clearTimeout(this.timeID);
    this.timeID = setTimeout(() => {
      this.setState({ curSub: { ...this.state.curSub, [id]: si } });
    }, time)
  }
  hdFeaNav = (feaID, icon) => {
    this.setState({ curFea: feaID });
    const ele = this.refs['stage_' + feaID];
    if (!ele) return;

    const { isMobile } = this.props;
    const roleHeadRect = this.refs[icon + '_head'].getClientRects()[0];
    // console.log(roleHeadRect)
    // console.log(this.props.scrollTop)
    // console.log(ele.getClientRects()[0].top)
    window.scrollTo(0, document.documentElement.scrollTop + ele.getClientRects()[0].top - roleHeadRect.height - 55 - (isMobile ? 30 : -30))

    //todo:auto show subitems
  }
  hdRoleNav = (id) => {
    const ele = this.refs[id];
    if (!ele) return;

    // console.log(ele)
    // console.log(this.props.scrollTop)
    // console.log(ele.getClientRects()[0].top)
    window.scrollTo(0, document.documentElement.scrollTop + ele.getClientRects()[0].top - 55 + 30);
  }

  render() {
    const { curFea, curSub, curRole } = this.state;
    const { isMobile } = this.props;

    return (
      <div>
        <div>
          <h2 className='f-tc f-mt-3'>酷课场地预订/约课系统怎么用？</h2>
          <div className={st.iconNav}>
            {
              roleFeatures.map(v => (
                <div key={v.icon} className={st.iconNavItem}>
                  <div className={st.iconNavItemWrap} onClick={this.hdRoleNav.bind(null, v.icon)}>
                    {/* <Svg svgClass={st.iconNavIcon} id={v.icon} /> */}
                    <img src={`${CDNBASE}/field/role_avatar/${v.icon}.png`} className={st.iconNavIcon} />
                    <div className={st.mask}></div>
                    <div className={st.iconNavTitle}><h3>{v.title}</h3></div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
        <div className={st.wrapper}>
          <div className={st.sideLine}></div>
          {
            roleFeatures.map(({ icon, items }) => (
              <section key={icon} className={classNames(st.section, { [st.active]: !!curRole })} ref={icon}>
                <div className={classNames(st.roleHead, { [st.cur]: curRole === icon })} ref={`${icon}_head`}>
                  <div className={st.roleIcon}><img src={`${CDNBASE}/field/role_avatar/${icon}.png`} /></div>
                  {/* <div className={st.roleIcon}><Svg svgClass={st.roleSvg} id={icon} /></div> */}
                  <div className={st.role}>
                    <div className={st.roleOpts}>
                      {
                        roleFeatures.map(v => {
                          if (v.icon === icon) {
                            return (<div key={v.icon} className={st.roleCur}>
                              <h2>{v.title}</h2>
                            </div>)
                          }
                          return (<b key={v.icon} onClick={this.hdRoleNav.bind(null, v.icon)} className={st.roleOpt}>{v.title}</b>);
                        })
                      }
                    </div>
                    <div className={st.feaOpts}>
                      {!isMobile && (<span className={st.ican}>我可以 </span>)}
                      {
                        items.map(v => (
                          <b key={v.id} className={classNames(st.feaBtn, { [st.cur]: curFea === v.id })} onClick={this.hdFeaNav.bind(null, v.id, icon)}>
                            {v.fullName}
                          </b>
                        ))
                      }
                    </div>
                  </div>
                </div>

                <div className={st.stages}>
                  {
                    items.map(v => (
                      <div key={v.id} className={classNames(st.stage, { [st.cur]: curFea === v.id })} ref={'stage_' + v.id}>
                        <div className={st.feaWrap}>
                          <div className={st.feaBar}>
                            <div className={st.feaBarWrap}>
                              <Svg svgClass={st.feaSvg} id={v.icon || v.id} />
                            </div>
                            <h3>{v.name}</h3>
                          </div>
                          <div
                            className={st.subItems}
                          >
                            {
                              v.subItems.map((sv, si) => (
                                <div
                                  onClick={this.setCurSub.bind(null, v.id, si, 0)}
                                  key={sv.title + si}
                                  className={classNames(st.subItem, { [st.cur]: (curSub[v.id] || 0) === si })}
                                >
                                  <h4><span className={st.dot}></span>{sv.title}</h4>
                                  <p>{sv.desc}</p>
                                </div>
                              ))
                            }
                          </div>
                        </div>
                        {!isMobile && (<div className={st.picsShow}>
                          {
                            v.subItems.map((sv, si) => (
                              <div
                                key={sv.title + si}
                                className={classNames(st.showWrap, { [st.cur]: (curSub[v.id] || 0) === si })}
                              >
                                <div className={st.showBg}></div>
                                  <img
                                    className={st.pic}
                                    src={`${CDNBASE}/field/role_demo/${v.id}/${si}.${sv.picTyp || 'png'}?013`}
                                    alt={sv.title}
                                  />
                                  <h4 className={st.showTitle}>{sv.title}</h4>
                                
                              </div>
                            ))
                          }

                        </div>)}
                      </div>
                    ))
                  }
                </div>
              </section>
            ))
          }
        </div>
      </div>
    )
  }
}

export default RoleFeature
